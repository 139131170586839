import "./src/styles/global.css"

// custom typefaces
import "typeface-montserrat"
import "prismjs/themes/prism.css"

import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
